import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions';

export default () => ({
    state: {
        isInitialized: false,
        searchStatus: 'pending', // 'resolved' or 'pending'
        cookie: 'safetyTopics_search',
        options: [],
        relevanceOptions: [],
        channelOptions: [],
        results: [],
        filteredResults: [],
        query: '',
        page: 1,
    },
    actions: {
        ...actions,
    },
    getters: {
        // the results filtered by the "relevance" filter only.
        countedResults(state, getters, rootState) {
            const relevanceFilter = rootState.relevance_select && rootState.relevance_select.value;
            if (!relevanceFilter)  {
                return state.results;
            }
            return state.results.filter(
                result => result.relevance.some(e => e.value === relevanceFilter));
        },
        channelOptionsWithCounts(state, getters) {
            const initializedOptions = [...state.channelOptions].map(
                opt => ({
                    ...opt,
                    disabled: true,
                    count: 0,
                }));
            if (state.results.length < 1) {
                return initializedOptions;
            }
            return getters.countedResults.reduce((options, result) => {

                let index = options.findIndex(option => option.value === result.channel.value);
                if (index > -1) {
                    options[index].count++;
                    options[index].disabled = false;
                }

                return options;
            }, initializedOptions);

        },
    },
});