import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';

export default () => ({
    state: {
        page: 1,
        loading: false,
        searchOption: '1',
        subjectsOrHazardsOptions: [],
        videoFormatOptions: [],
        videoCatalogSearchResults: [],
        filteredResults: [],
        videoFormatValue: 2,
        subjectValue: 0,
        searchTermSpecified: '',
        isSearchInvoked: false,
        detailInfo: {
            titleId: '',
            legacyId: '',
            titleName: '',
            altTitleName: '',
            videoLength: '',
            description: '',
            languages: '',
            productionDate: '',
            isOnlineOnly: false,
            isLimitedView: false,
            titleFormats: [],
        },
        detailAdditionalInfo: {
            titleId: '',
            titleName: '',
            foreignTitleName: '',
            altTitleName: '',
            languages: '',
            formats: '',
            subjects: '',
            shortDescription: '',
            copyright: '',
            videoLength: '',
            hasClosedCaption: '',
            vendorName: '',
            vendorEmail: '',
            vendorWebsite: '',
            vendorPhone: '',
            vendorStatus: '',
            isLimitedView: false,
            isOnlineOnly: false,
            relatedTitles: [],
            webInfoList: [],
            title: '',
            messages: '',
            success: '',
            status: '',
        },
        customInfo: {
            isViewOnlineorDownloadVideo: false,
            embeddedVideoWidth: '',
            embeddedVideoHeight: '',
            embeddedVideoSource: '',
            embeddedVideoFrameBorder: '',
        },
    },
    mutations: {
        SET_SEARCH_OPTION(state, payload) {
            state.searchOption = payload;
        },
        SET_SUBJECTS_OR_HAZARD_OPTIONS(state, payload) {
            state.subjectsOrHazardsOptions = payload;
        },
        SET_SEARCH_TERM_SPECIFIED(state, payload) {
            state.searchTermSpecified = payload;
        },
        SET_SEARCH_INVOKED(state, payload) {
            state.isSearchInvoked = payload;
        },
        SET_VIDEO_FORMAT_OPTIONS(state, payload) {
            state.videoFormatOptions = payload;
        },
        SET_VIDEO_FORMAT_VALUE(state, payload) {
            state.videoFormatValue = payload;
        },
        SET_VIDEO_FORMAT_TEXT(state, payload) {
            state.videoFormatText = payload;
        },
        SET_SUBJECT_VALUE(state, payload) {
            state.subjectValue = payload;
        },
        SET_SUBJECT_TEXT(state, payload) {
            state.subjectText = payload;
        },
        SET_VIDEO_CATALOG_SEARCH_RESULTS(state, payload) {
            state.videoCatalogSearchResults = payload;
            state.filteredResults
            = state.videoCatalogSearchResults;
        },
        SET_FILTER_CATALOG_SEARCH_RESULTS_BY_FORMAT(state, payload) {
            state.filteredResults
            = state.videoCatalogSearchResults.filter(item =>
                    item.titleFormats.includes(payload));
        },
        SET_UNFILTERED_CATALOG_SEARCH_RESULTS(state) {
            state.filteredResults = state.videoCatalogSearchResults;
        },
        UPDATE_LOADING_STATE: (state, isLoading) => {
            state.loading = isLoading;
        },
        SET_VIDEO_DETAIL(state, payload) {
            state.detailInfo.titleId = payload.titleId;
            state.detailInfo.legacyId = payload.legacyId;
            state.detailInfo.titleName = payload.titleName;
            state.detailInfo.altTitleName = payload.altTitleName;
            state.detailInfo.videoLength = payload.videoLength;
            state.detailInfo.description = payload.description;
            state.detailInfo.languages = payload.languages;
            state.detailInfo.productionDate = payload.productionDate;
            state.detailInfo.isOnlineOnly = payload.isOnlineOnly;
            state.detailInfo.isLimitedView = payload.isLimitedView;
            state.detailInfo.titleFormats = [];
            state.detailInfo.titleFormats.push(...payload.titleFormats);
        },
        SET_VIDEO_ADDITIONAL_DETAIL(state, payload) {
            state.detailAdditionalInfo.titleId = payload.titleId;
            state.detailAdditionalInfo.titleName = payload.titleName;
            state.detailAdditionalInfo.foreignTitleName = payload.foreignTitleName;
            state.detailAdditionalInfo.altTitleName = payload.altTitleName;
            state.detailAdditionalInfo.languages = payload.languages;
            state.detailAdditionalInfo.formats = payload.formats;
            state.detailAdditionalInfo.subjects = payload.subjects;
            state.detailAdditionalInfo.shortDescription = payload.shortDescription;
            state.detailAdditionalInfo.copyright = payload.copyright;
            state.detailAdditionalInfo.videoLength = payload.videoLength;
            state.detailAdditionalInfo.hasClosedCaption = payload.hasClosedCaption;
            state.detailAdditionalInfo.vendorName = payload.vendorName;
            state.detailAdditionalInfo.vendorEmail = payload.vendorEmail;
            state.detailAdditionalInfo.vendorWebsite = payload.vendorWebsite;
            state.detailAdditionalInfo.vendorPhone = payload.vendorPhone;
            state.detailAdditionalInfo.vendorStatus = payload.vendorStatus;
            state.detailAdditionalInfo.isLimitedView = payload.isLimitedView;
            state.detailAdditionalInfo.isOnlineOnly = payload.isOnlineOnly;
            state.detailAdditionalInfo.relatedTitles = [];
            state.detailAdditionalInfo.relatedTitles.push(...payload.relatedTitles);
            state.detailAdditionalInfo.webInfoList = [];
            state.detailAdditionalInfo.webInfoList.push(...payload.webInfoList);
            state.detailAdditionalInfo.title = payload.title;
            state.detailAdditionalInfo.messages = payload.messages;
            state.detailAdditionalInfo.success = payload.success;
            state.detailAdditionalInfo.status = payload.status;
        },
        CLEAR_VIDEO_DETAIL(state) {
            state.detailInfo = {};
        },
        CLEAR_VIDEO_ADDITIONAL_DETAIL(state) {
            state.detailAdditionalInfo = {};
        },
        SANITIZE_ADDITIONAL_DETAIL_DESCRIPTION(state) {
            state.detailAdditionalInfo.shortDescription
            = state.detailAdditionalInfo.shortDescription.replace(/<br\s*\/?>/g, '\n\n');
        },
    },
    getters: {
        getSearchOption(state) {
            return state.searchOption;
        },
        getSubjectsOrHazards(state) {
            return state.subjectsOrHazardsOptions;
        },
        getVideoFormats(state) {
            return state.videoFormatOptions;
        },
        getSearchTermSpecified(state) {
            return state.searchTermSpecified;
        },
        getSubjects(state) {
            return state.subjectsOrHazardsOptions;
        },
        getVideoFormatValue(state) {
            return state.videoFormatValue;
        },
        getVideoFormatText(state) {
            let convertedSelectionValue = helper.convertToNumber(state.videoFormatValue,
                sharedConstants.all);

            let filter = state.videoFormatOptions.filter(item =>
                item.value === convertedSelectionValue);

            return filter[0].text;
        },
        getSubjectValue(state) {
            return state.subjectValue;
        },
        getSubjectText(state) {

            if (helper.isNullOrEmptyString(state.subjectValue)) {
                return '';
            }

            let convertedSelectValue = helper.convertToNumber(state.subjectValue, null);

            let filter = state.subjectsOrHazardsOptions.filter(item =>
                item.value === convertedSelectValue);

            return filter[0].text;
        },
        getVideoDetail(state) {
            return state.videoDetail;
        },
        getAdditionalDetailShortDescription(state) {
            return state.detailAdditionalInfo.shortDescription;
        },
    },
    actions: {
        ...actions,
    },
});