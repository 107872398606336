const sharedConstants = {
    MUTATIONS: {
        setSearchOption: 'SET_SEARCH_OPTION',
        setSubjectsOrHazardsOptions: 'SET_SUBJECTS_OR_HAZARD_OPTIONS',
        setVideoFormatOptions: 'SET_VIDEO_FORMAT_OPTIONS',
        setVideoCatalogSearchResults: 'SET_VIDEO_CATALOG_SEARCH_RESULTS',
        setSearchTermSpecified: 'SET_SEARCH_TERM_SPECIFIED',
        setSearchInvoked: 'SET_SEARCH_INVOKED',
        setFilterCatalogSearchResultsByFormat: 'SET_FILTER_CATALOG_SEARCH_RESULTS_BY_FORMAT',
        setUnfilteredCatalogSearchResults: 'SET_UNFILTERED_CATALOG_SEARCH_RESULTS',
        updateLoadingState: 'UPDATE_LOADING_STATE',
        setVideoFormatValue: 'SET_VIDEO_FORMAT_VALUE',
        setVideoFormatText: 'SET_VIDEO_FORMAT_TEXT',
        setSubjectValue: 'SET_SUBJECT_VALUE',
        setSubjectText: 'SET_SUBJECT_TEXT',
        setVideoDetail: 'SET_VIDEO_DETAIL',
        setAdditionalVideoDetail: 'SET_VIDEO_ADDITIONAL_DETAIL',
        clearVideoDetail: 'CLEAR_VIDEO_DETAIL',
        clearVideoAdditionalDetail: 'CLEAR_VIDEO_ADDITIONAL_DETAIL',
        sanitizeAdditionalDetailDescription: 'SANITIZE_ADDITIONAL_DETAIL_DESCRIPTION',
        setAttribute: 'setAttribute',
    },
    DIALOGS: {
        dialogException: 'dialogException',
    },
    ACTIONS: {
        setFilteredCatalogSearchResultsByFormat: 'setFilteredCatalogSearchResultsByFormat',
    },
    GETTERS: {
        getSearchOption: 'getSearchOption',
        getSubjectsOrHazards: 'getSubjectsOrHazards',
        getVideoFormats: 'getVideoFormats',
        getVideoFormatType: 'getVideoFormatType',
        getSelectedVideoFormatTypeText: 'getSelectedVideoFormatTypeText',
        getSelectedSubjectText: 'getSelectedSubjectText',
    },
    FIELDS: {
        subjectHazardsList: 'search_subjectOrHazards',
        videoFormatsList: 'search_videoFormats',
    },
    ATTRIBUTES: {
        value: 'value',
    },
    allValue: 2,
    allText: 'All',
    keywordSearch: '1',
    subjectSearch: '2',
};

export default sharedConstants;