<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <a
            href
            class="lni-u-type--medium lni-c-shrl-card__title lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
            @click.prevent="$emit('hide-detail')"
        >
            <span
                aria-hidden="true"
                class="lnicon--caret--left"
            />
            Video Library Search
        </a>
        <div>
            <div class="lni-u-mb1">
                <h2 class="lni-u-heading--3 lni-u-mb2 lni-u-mt2">
                    <ul class="lni-u-ml2">
                        <li>{{ $props.titleName }}</li>
                        <li>{{ $props.foreignTitleName }}</li>
                    </ul>
                </h2>
            </div>
            <div>
                {{ sanitizedDescription }}
            </div>
            <div
                v-if="enableLegacyFeatures.subjectsCovered"
                class="lni-u-mt2"
            >
                Subjects Covered: {{ $props.subjects }}
            </div>
            <div class="lni-u-flex lni-u-gap6 lni-u-mt2">
                <div class="lni-u-flex-row">
                    <div class="lni-u-flex-column">
                        <div>
                            Language(s): {{ $props.languages }}
                        </div>
                        <div>
                            Release Date: {{ $props.copyright }}
                        </div>
                        <div>
                            Length: {{ $props.videoLength }}
                        </div>
                    </div>
                </div>
                <div class="lni-u-flex-row">
                    <div class="lni-u-flex-column">
                        <div>
                            Video ID: {{ $props.titleId }}
                        </div>
                        <div>
                            Format(s):
                            <span v-if="isDvd">
                                <img
                                    src="../lni-safety-video-search/images/dvd.gif"
                                    alt="DVD"
                                    class="lni-u-mr1"
                                >
                            </span>
                            <span v-if="isOnline">
                                <img
                                    src="../lni-safety-video-search/images/online.png"
                                    alt="Online"
                                    class="lni-u-mr1"
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="enableLegacyFeatures.closedCaptioningSubtitles">
                Closed Captioning/Subtitles: {{ isClosedCaption }}
            </div>
            <div class="lni-u-mt2">
                <a
                    href="https://lni.wa.gov/forms-publications/F417-206-000.pdf"
                    target="_blank"
                >
                    Complete and email the Video Reservation Form (F417-206-000)
                </a>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
import isNullOrEmptyString from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/isNullOrEmptyString';

export default {
    name: 'LniSafetyVideoSearchDetail',
    emits: ['hide-detail', 'hideDetail'],
    data() {
        return {
            enableLegacyFeatures: {
                subjectsCovered: true,
                closedCaptioningSubtitles: true,
                languagesAvailable: true,
            },
        };
    },
    computed: {
        sanitizedDescription() {
            this.$store.commit('search/SANITIZE_ADDITIONAL_DETAIL_DESCRIPTION');
            return this.$store.getters['search/getAdditionalDetailShortDescription'];
        },
        isClosedCaption() {
            return this.closedCaptioningSubtitles ? 'Yes' : 'No';
        },
        isDvd() {
            if (isNullOrEmptyString(this.formats)) {
                return false;
            }
            let includesDVD = this.formats.toLowerCase().includes('dvd');
            return includesDVD;
        },
        isOnline() {
            if (isNullOrEmptyString(this.formats)) {
                return false;
            }
            let includesOnline = this.formats.toLowerCase().includes('online');
            return includesOnline;
        },
    },
    /* eslint-disable */
    props:[
    'titleName',
    'foreignTitleName',
    'titleId',
    'videoLength',
    'copyright',
    'languages',
    'description',
    'formats',
    'subjects',
    'closedCaptioningSubtitles'
    ],
    /* eslint-enable */
};
</script>