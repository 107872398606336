export const formFields = [
    {
        id: 'contact-person',
        label: 'Contact person',
    },
    {
        id: 'job-title',
        label: 'Job title',
    },
    {
        id: 'contact-phone_phone',
        label: 'Phone',
    },
    {
        id: 'contact-phone_ext',
        label: 'Ext.',
    },
    {
        id: 'contact-email_input-text',
        label: 'Contact Email',
    },
    {
        id: 'contact-preference_input-text',
        label: 'Which contact method and time of day do you prefer?',
    },
    {
        id: 'company-name',
        label: 'Company name',
    },
    {
        id: 'address_zip',
        label: 'Zip',
    },
    {
        id: 'county',
        label: 'County',
    },
    {
        id: 'ubi',
        label: 'WA UBI no.',
    },
];