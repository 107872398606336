<template>
    <div class="lni-u-mt2">
        <div class="lni-c-late-payment__input-container">
            <div class="lni-u-flex lni-u-flex-wrap">
                <lni-input-text
                    :id="`${id}_num-premiums`"
                    class="lni-u-late-payment__input lni-c-late-payment__num-premiums"
                    type="number"
                    required
                    step=".01"
                    pattern="[0-9]*"
                    label-text="Total of premiums"
                    :dense="true"
                    :filled="false"
                    :change-action="['validateForm', `${id}/calculate`]"
                />
                <lni-input-text
                    :id="`${id}_num-months`"
                    class="lni-u-late-payment__input"
                    type="number"
                    required
                    step=".01"
                    pattern="[0-9]*"
                    label-text="Number of months late"
                    :dense="true"
                    :filled="false"
                    :change-action="['validateForm', `${id}/calculate`]"
                />
            </div>
            <lni-error-container
                :id="`${id}_error-container`"
                heading=""
                :errors-collected-from="[
                    `${id}_num-premiums`,
                    `${id}_num-months`,
                ]"
            />

            <div class="lni-u-mb3 lni-u-mt2">
                <lni-button
                    :id="id + '_calculate'"
                    :click-action="['validateForm', `${id}/calculate`]"
                    text="Calculate"
                    class="lni-c-button --primary"
                    :error-container-id="`${id}_error-container`"
                />
            </div>
        </div>
        <div
            aria-live="polite"
        >
            <h2 class="lni-c-late-payment__result lni-u-type--normal lni-u-mb1">
                Result
            </h2>
            <lni-input-display
                :id="id + '_result'"
                label-text="Penalty for late reporting"
            />
            <lni-input-display
                :id="id + '_result1'"
                label-text="Interest"
            />
            <lni-input-display
                :id="id + '_result2'"
                label-text="Amount due for this quarter"
            />
        </div>
    </div>
</template>


<script>

export default {
    name: 'LniLatePayment',
    mounted() {
        this.addCustomMessages();
    },
    methods: {
        addCustomMessages() {
            this.$store.commit(`${this.id}_num-premiums/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: () =>
                    `Please enter the total of premiums.`,
                inline: () => 'Please fill out this field',
            });
            this.$store.commit(`${this.id}_num-months/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: () =>
                    `Please enter the total of number of months.`,
                inline: () => 'Please fill out this field',
            });
        },
    },
}; </script>
<style lang="scss">
@import 'lni-late-payment';
</style>