import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import VideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/classes/videoDetail';
//import helper from './helpers';

export default function loadVideoDetail(context:any, payload:any) {
    const oneLni = payload.oneLni;
    context.commit(sharedConstants.MUTATIONS.updateLoadingState, true);

    let videoDetail = new VideoDetail(payload.titleId);

    postToService(videoDetail.getVideoDetail,
        oneLni).then((response:
                {
                    ok: any;
                    data: any;
                }) => {

        if (!response.ok) {
            context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
            //helper.showDialog(context, 'dialogException', true, true);
            return;
        }
        context.commit(sharedConstants.MUTATIONS.setVideoDetail, response.data);

        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
    });
}