<template>
    <form
        class="lni-c-consultation-request lni-u-mt3 lni-u-form-width--base"
        @input="onFormChange($event)"
        @change="onFormChange($event)"
    >
        <fieldset class="contact-wrapper lni-u-mt3">
            <legend class="lni-u-heading--3">
                Contact Information
            </legend>
            <lni-input-text
                :id="`${id}_contact-person`"
                ref="contact-person"
                labelText="Name"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-text
                :id="`${id}_job-title`"
                ref="job-title"
                labelText="Job Title"
                :filled="true"
                :labelBefore="true"
                class="lni-u-mt3"
                :fullWidth="true"
            />
            <lni-input-phone
                :id="`${id}_contact-phone`"
                ref="contact-phone"
                labelText="Phone"
                :isFieldset="false"
                :filled="true"
                :required="true"
                class="contactPhone"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-email
                :id="`${id}_contact-email`"
                ref="contact-email"
                labelText="Email"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-textarea
                :id="`${id}_contact-preference`"
                ref="contact-preference"
                labelText="Which contact method and time of day do you prefer?"
                :filled="true"
                :limit="255"
                class="lni-u-mt3"
                :fullWidth="true"
            >
                <template #helperText>
                    <div>
&nbsp;
                    </div>
                </template>
            </lni-input-textarea>
        </fieldset>
        <fieldset class="company-wrapper lni-u-mt2">
            <legend class="lni-u-heading--3">
                Company Information
            </legend>
            <lni-input-text
                :id="`${id}_company-name`"
                ref="company-name"
                labelText="Company Name"
                :filled="true"
                :required="true"
                :labelBefore="true"
                class="lni-u-mt1"
                :fullWidth="true"
            />
            <lni-input-text
                :id="`${id}_ubi`"
                ref="ubi"
                v-constrain="upTo9digitNumber"
                labelText="WA UBI No."
                :filled="true"
                :labelBefore="true"
                class="lni-u-mt2"
                maxlength="9"
                pattern="[0-9]{9}"
                :fullWidth="true"
            >
                <template #helperText>
                    <span>Numbers only, no special characters</span>
                </template>
            </lni-input-text>
            <div
                class="lni-u-flex lni-u-gap2"
            >
                <lni-input-text
                    :id="`${id}_address_zip`"
                    class="lni-c-input-address__legend"
                    labelText="Zip Code"
                    :labelBefore="true"
                    :customWidth="8"
                />

                <lni-select
                    :id="`${id}_county`"
                    labelText="County"
                    labelBefore="true"
                    :required="true"
                    :options="counties"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <span>County is not needed if you are outside Washington State</span>
                    </template>
                </lni-select>
            </div>
        </fieldset>
        <lni-button
            :id="`${id}_submit`"
            ref="button"
            text="Submit Request"
            type="button"
            class="lni-u-mt3"
            errorContainerId="consultation-request-errors"
            :clickAction="['validateForm',`${id}/submit`]"
        />
        <lni-error-container
            id="consultation-request-errors"
            class="lni-u-mt2"
            heading="Before we submit your request"
            :errorsCollectedFrom="[
                `${id}_contact-person`,
                `${id}_contact-phone`,
                `${id}_contact-email`,
                `${id}_company-name`,
                `${id}_county`,
                `${id}_ubi`,
            ]"
        />
        <p>
            Contact <a href="mailto:DOSHconsultation@lni.wa.gov">DOSHconsultation@lni.wa.gov</a>
            or call <a href="tel:360-870-2269">360-870-2269</a> if you have problems completing this form.
        </p>
        <div v-if="showModalSpinner">
            <lni-spinner
                :id="`${id}_spinner`"
            />
        </div>
    </form>
</template>
<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';

export default {
    name: 'LniSafetyConsultationRequest',
    directives: {
        constrain,
    },
    data() {
        return {
            upTo8digitNumber: /^[0-9]{0,8}$/,
            upTo9digitNumber: /^[0-9]{0,9}$/,
        };
    },
    mounted() {
        this.$store.commit(`${this.id}/INITIALIZE`, {
            oneLni: this.$oneLni,
        });
        this.dispatchEvent(`${this.id}/syncUi`);
        this.addCustomMessages();
    },
    methods: {
        getLabel(id) {
            return this.$store.state[this.getFullId(id)].labelText;
        },
        getFullId(shortId) {
            return `${this.id}_${shortId}`;
        },
        onFormChange($event) {
            this.$store.dispatch(`${this.id}/syncStore`, {
                targetId: $event.target.id,
                sourceId: this.id,
                oneLni: this.$oneLni,
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_ubi/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `The ${state.labelText} must be 9 digits.`,
                inline: () =>
                    `Please enter a valid 9 digit UBI number`,
            });
        },
    },
}; </script>


<style lang="scss" scoped>
.contact-wrapper, .company-wrapper{
    margin-top:0;
    display:flex;
    flex-direction: column;
    padding:5px 0px 5px 0;
}

.contact-wrapper > *, .company-wrapper > *{
    margin:5px 0px 5px 0;
    width:100%;
}

.contactPhone > :first-child{
    justify-content: space-between;
}
</style>