import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

import {
    Vue,
    VueRouter,
} from '@gov.wa.lni/framework.one-lni.core/source/external';

function curryRoute(store: any, routeGuard: any) {
    return (to: any, from: any, next: any) => routeGuard(store, to, from, next);
}

/**
 * Sets up vue router for use by the framework and application components.
 * If options.enableRouter is false, Vue Router will not be added to the application.
 * @param framework The framework instance to set up.
 * @param options The framework options to use when configuring.
 */
export default function setupRouter(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    if (!options.enableRouter) {
        return;
    }

    Vue.use(VueRouter);

    options.router = options.routing?.mode ? new VueRouter({
        mode: options.routing.mode,
    }) : new VueRouter();

    if (options.enableRouteBasedAnalytics && framework.environment.current.supportsAnalytics) {
        options.router.afterEach((to: any) => {
            if (to.path !== '/' && !!window.ga) {
                window.ga('set', 'page', to.path);
                window.ga('send', 'pageview');
            }
        });
    }

    if (options.routing) {
        if (options.routing.navigationGuards) {
            if (options.routing.navigationGuards.beforeEach) {
                options.router.beforeEach(
                    curryRoute(options.store, options.routing.navigationGuards.beforeEach),
                );
            }
        }

        if (options.routing.routes) {
            options.routing.routes.forEach((r: any) => options.router.addRoute(r));
        }
    }

}