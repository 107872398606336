import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import loadLookupData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/loadLookupData';
import searchForVideos from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/searchForVideos';
import clearFilters from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/clearFilters';
import filterByFormat from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/filterByFormat';
import toggleSearchType from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/toggleSearchType';
import loadVideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/loadVideoDetail';
import loadAdditionalVideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/loadAdditionalVideoDetail';
import clearSearchResults from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/clearSearchResults';

export default {
    postToService,
    loadLookupData,
    searchForVideos,
    clearFilters,
    clearSearchResults,
    filterByFormat,
    toggleSearchType,
    loadVideoDetail,
    loadAdditionalVideoDetail,
};