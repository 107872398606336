import encodeValue from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/encodeValue.js';
import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-search.api.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;

    const utils = payload.oneLni.storeUtils;
    //Show spinner
    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'searchStatus',
        value: 'pending',
    }, {
        root: true,
    });

    setTimeout(() => {
        const id = payload.targetId.replace('_text', '');
        const query = utils.getValue(context, id);
        const encodedValue = encodeValue(query);

        let url = `/safety-health/safety-topics/search-by-topic?index=Safety_Topics`;
        if (query.length > 0) {
            url += `&query=${encodedValue}`;
        }

        history.pushState({
            query: encodedValue,
        }, document.title, url);

        search(encodedValue, payload.oneLni).then(results => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'results',
                value: results,
            }, {
                root: true,
            });


            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'page',
                value: 1,
            }, {
                root: true,
            });

            context.dispatch(`clearFilters`, payload);
            context.dispatch(`updatePage`, payload);
            context.dispatch('updateMultiselectOptions');

            //Hide spinner
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'searchStatus',
                value: 'resolved',
            }, {
                root: true,
            });
        });
    });
};