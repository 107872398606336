<template>
    <div>
        Looking for ideas on how to prevent sprains and strains? Use this tool to find solutions by industry and/or risk factor.

        <div class="lni-c-ergonomic-ideas-filter lni-u-mt3 lni-u-mb2">
            <button
                :id="`${id}_disclosure-title`"
                class="lni-u-link-style lni-u-type--bold lni-u-type--xs lni-u-text--all-caps lni-u-pl0"
                :aria-expanded="`${disclosureIsExpanded}`"
                :aria-controls="`${id}_disclosure-message`"
                role="button"
                @click="disclosureIsExpanded = !disclosureIsExpanded"
            >
                Important Note
                <span
                    :class="`${disclosureIsExpanded ? 'lnicon--triangle--up' :
                        'lnicon--triangle--down' }`"
                    aria-hidden="true"
                />
            </button>
            <div
                :id="`${id}_disclosure-message`"
                v-slide="disclosureIsExpanded"
                :aria-hidden="`${!disclosureIsExpanded}`"
                class="lni-u-pv1 lni-u-type--xs lni-u-line-height--tight"
            >
                <div class="lni-u-pb1">
                    These ergonomics solutions have been reviewed by L&amp;I ergonomists to ensure
                    they are consistent in the practice of ergonomics. The presence of a solution on
                    this site does not guarantee that it will eliminate any particular hazardous
                    exposures.
                </div>
                <div class="lni-u-pv1">
                    Links to outside resources do not represent or imply the endorsement of any
                    commercial product and are provided as a courtesy. For more information,
                    read <a
                        href="https://lni.wa.gov/agency/content-policy/"
                        target="_blank"
                    >L&I's Intended Usage policy</a>.
                </div>
            </div>
        </div>
        <div
            v-show="isError === false"
            class="lni-u-flex lni-u-flex-column lni-ergonomic-ideas__inputs"
        >
            <lni-select
                id="industrySelect"
                class="lni-u-full-width"
                :options="industryOptions"
                labelText="For what industry?"
                :value="industryValue"
                :changeAction="`${id}/onFilterChange`"
            />
            <lni-select
                id="hazardSelect"
                class="lni-u-mt2 lni-u-full-width"
                :options="hazardOptions"
                labelText="For what hazard?"
                :value="hazardValue"
                :changeAction="`${id}/onFilterChange`"
            />
            <lni-input-text
                id="keywordInput"
                class="lni-u-mv3"
                :dense="true"
                :filled="false"
                labelText="Filter by keyword (optional)"
                :inputAction="`${id}/onFilterChange`"
                :value="filterKeywords"
            >
                <template #suffix>
                    <button
                    
                        class="lni-u-button-reset"
                        @click="clearInput"
                    >
                        <span
                            aria-hidden="true"
                            class="lni-c-icon lnicon--cancel-circle"
                        />
                    </button>
                </template>
            </lni-input-text>
            <lni-checkbox-list
                id="typeSelect"
                :options="contentTypeOptionsCounts"
                labelText="Content type"
                :value="contentTypeValue"
                :changeAction="`${id}/onFilterChange`"
            />
        </div>
        <lni-fetch
            id="ergonomics-api"
            class="lni-c-ergonomic-ideas__results"
            :fullEndpoint="apiEndpoint"
            :successAction="`${id}/onSuccess`"
            :errorAction="`${id}/onError`"
        >
            <div
                v-show="isIdle === false"
                class="lni-ergonomic-ideas__results lni-u-mt2 lni-u-pt2"
            >
                <div
                    v-if="isError || !filteredResults"
                    class="lni-c-ergonomics-ideas__error-message"
                >
                    {{ errorText }}
                </div>
                <div v-else>
                    <div v-if="totalItems < 1">
                        <p>
                            <span>We could not find any items</span>
                            <span v-if="filterKeywords.length > 0">containing "{{ filterKeywords }}"</span>
                            <span>with:</span>
                        </p>
                        <ul class="lni-u-pl3 lni-u-mt0">
                            <li>Industry: {{ selectedIndustry }}</li>
                            <li>Hazard: {{ selectedHazard }}</li>
                            <li>
                                Content Type<span v-if="contentTypeValue.length > 1">s</span>:
                                <span v-if="selectedTypes.length > 0">
                                    {{ selectedTypes }}
                                </span>
                                <span v-else>
                                    None
                                </span>
                            </li>
                        </ul>
                        <div
                            class="lni-u-mt2"
                        >
                            <p>
                                Please try a different search.
                                <span v-if="filterKeywords.length > 0">
                                    Try searching using some of the following tips:
                                    <ul class="lni-u-pl3">
                                        <li>Make sure all words are spelled correctly</li>
                                        <li>Try different keywords</li>
                                        <li>Try more general keywords</li>
                                        <li>Try fewer keywords</li>
                                    </ul>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <p
                            class="lni-c-ergonomics-ideas__active-filters lni-u-text--xs"
                        >
                            {{ selectedIndustry }} <span aria-hidden="true">></span> {{ selectedHazard }} Total: {{ totalItems }}
                        </p>
                        <div
                            v-for="item in pageOfItems"
                            :key="item.id"
                            class="lni-u-flex lni-u-shadow--xs lni-u-border-radius--small lni-u-pa2 lni-u-mt2"
                        >
                            <div>
                                <a
                                    class="lni-u-text--no-underline lni-u-type--bold lni-u-pl0"
                                    :href="item.route"
                                    target="_blank"
                                >
                                    {{ item.title }}
                                </a>
                                <div
                                    class="lni-u-mt2 lni-u-mb3 lni-u-type--xs lni-u-line-height--tight"
                                >
                                    {{ item.description }}
                                </div>
                                <span class="lni-u-type--xxs lni-u-text--all-caps">{{ contentTypeText(item.type) }}</span>
                            </div>
                            <span
                                :class="item.fileType === 'pdf' ? 'lnicon--pdf' : (item.fileType === 'doc' || item.fileType === 'docx' ? 'lnicon--document' : null)"
                                class="lni-ergonomic-ideas--icon lni-u-mlauto lni-u-mr0 lni-u-type--xl"
                                aria-hidden="true"
                            />
                        </div>
                        <lni-pager
                            id="pager"
                            :collectionId="id"
                            class="lni-u-pt3"
                            :page="page"
                            :totalItems="totalItems"
                            :pageSize="pageSize"
                            scrollTarget=".lni-c-ergonomic-ideas-filter"
                            selectLabel="Items per Page"
                            @change-page="onChangePage"
                            @change-page-size="onChangePageSize"
                        />
                    </div>
                </div>
            </div>
        </lni-fetch>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';


export default {
    name: 'LniErgonomicIdeas',
    directives: {
        slide,
    },

    data() {
        return {
            apiEndpoint: `${this.$oneLni.environment.current.apiLocation2}/ergonomic-ideas`,
            disclosureIsExpanded: false,
        };
    },
    computed: {
        fetchState() {
            return this.$store.state['ergonomics-api'] || 'idle';
        },
        isError() {
            return this.fetchState.currentState === 'error';
        },
        isIdle() {
            return this.fetchState.currentState === 'idle';
        },
        errorText() {
            return `There was a problem with the network. Please try again. If you continue to get this error, contact Rick Goggins. Details: ${this.fetchState.error}`;
        },
        selectedIndustry() {
            return this.industryOptions.find(option => option.value === this.industryValue).text;
        },
        selectedHazard() {
            return this.hazardOptions.find(option => option.value === this.hazardValue).text;
        },
        selectedTypes() {
            let result = '';
            this.contentTypeOptions.forEach(option => {
                for (let i = 0; i < this.contentTypeValue.length; i++) {
                    if (option.value === this.contentTypeValue[i]) {
                        result += option.text + ((i + 1) < this.contentTypeValue.length ? ', ' : '');
                    }
                }
            });

            return result;
        },
        pageOfItems() {
            let startItem = (this.page - 1) * this.pageSize;
            return this.filteredResults.slice(startItem, startItem + this.pageSize);
        },
    },
    mounted() {
        this.dispatchEvent(`${this.id}/onInitialize`);
    },
    methods: {
        onChangePage(data) {
            this.page = data.page;
        },
        onChangePageSize(data) {
            this.pageSize = data.pageSize;
        },
        clearInput() {
            this.$store.commit('setAttribute', {
                id: 'keywordInput',
                attribute: 'value',
                value: '',
            });

            this.$store.dispatch(`${this.id}/onFilterChange`);
        },
        contentTypeText(type) {
            return this.contentTypeOptions.find(option => option.value === type).text;
        },
    },

};
</script>
<style lang="scss">
@import 'lni-ergonomic-ideas';
</style>