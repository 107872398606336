/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import IStorageProxy, { StorageRecord } from '@gov.wa.lni/framework.one-lni.core/source/IStorageProxy';
import MemoryStorage from '@gov.wa.lni/framework.one-lni.core/source/MemoryStorage';

/**
 * Wraps a browser Storage object in a proxy for use.
 * Automatically provides a fallback MemoryStorage if the Storage object is not usable.
 */
export default class StorageProxy implements IStorageProxy {
    private _fallback: MemoryStorage;
    private _backingStore: Storage | null;
    private _available;

    /**
     * Tests a browser Storage object for availability.
     * @param storage The browser Storage object to test.
     * @returns true if the current environment supports the storage object, false if not.
     */
    static detectSupport(storage?: Storage | null): boolean {
        if (!storage) {
            return false;
        }

        try {
            storage.setItem('__test__', 'support');
            storage.removeItem('__test__');

            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Creates a new storage proxy. If backingStore is undefined or null, a MemoryStore is used instead.
     * @param backingStore The browser storage object.
     */
    constructor(backingStore?: Storage | null) {
        this._fallback = new MemoryStorage();

        if (backingStore) {
            this._backingStore = backingStore;
        } else {
            this._backingStore = null;
        }

        this._available = StorageProxy.detectSupport(this._backingStore);
    }

    loadMeta(name: string): StorageRecord | null {
        if (!this.has(name)) {
            return null;
        }

        if (!this.available) {
            return this._fallback.loadMeta(name);
        }

        const valueString = this._backingStore?.getItem(name);

        if (!valueString) {
            return null;
        }

        return JSON.parse(valueString);
    }

    load(name: string): any | null {
        if (!this.available) {
            return this._fallback.load(name);
        }

        const record = this.loadMeta(name);

        return record ? record.data : null;
    }

    has(name: string): boolean {
        if (!this.available) {
            return this._fallback.has(name);
        }

        return !!this._backingStore?.getItem(name);
    }

    remove(name: string): void {
        if (!this.available) {
            this._fallback.remove(name);
            return;
        }

        this._backingStore!.removeItem(name);
    }

    save(name: string, data: any): void {
        if (!this.available) {
            this._fallback.save(name, data);
            return;
        }

        const record: StorageRecord = {
            type: typeof data,
            modified: Date.now(),
            data,
        };

        this._backingStore!.setItem(name, JSON.stringify(record));
    }

    clear(): void {
        if (!this.available) {
            this._fallback.clear();
            return;
        }

        this._backingStore!.clear();
    }

    get available(): boolean {
        return this._available;
    }
}