<template>
    <div>
        <h2 class="lni-u-heading--2">
            {{ selectedDate }}
        </h2>
        Select a different month of the year.
        <div
            v-for="(months, year) in monthsByYear"
            :key="year"
            class="lni-u-flex">
            <div class="lni-u-mt2 lni-u-mr1">
                <div class="lni-c-date-filter__date">
                    <div class="lni-c-date-filter__date-year">
                        {{ year }}
                    </div>
                </div>
            </div>
            <div class="lni-u-flex lni-u-flex-wrap">
                <div
                    v-for="(month, index) in months"
                    :key="index"
                    class="lni-c-date-filter__date lni-u-mt2 lni-u-mr1">
                    <button
                        class="lni-c-date-filter__date-month"
                        :class="`${selectedMonth === month.toString() && selectedYear === year.toString() ? '--active' : ''}`"
                        @click="setSelectedDate(month, year)">
                        {{ new Date(year, month, '1').toLocaleDateString('en', {
                            month: 'short',
                        })
                        }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-date-filter',
    data() {
        return {
            monthsByYear: null,
            minDate: {
                month: null,
                year: null,
            },
            maxDate: {
                month: null,
                year: null,
            },
        };
    },
    computed: {
        isMinDate() {
            return this.selectedMonth === this.minDate.month && this.selectedYear === this.minDate.year;
        },
        isMaxDate() {
            return this.selectedMonth === this.maxDate.month && this.selectedYear === this.maxDate.year;
        },
        selectedDate() {
            let result = '';
            if (this.selectedMonth && this.selectedYear) {
                const d = new Date(this.selectedYear, this.selectedMonth, '1');
                const year = d.getFullYear().toString();
                const month = d.toLocaleDateString('en', {
                    month: 'long',
                });
                result = `${month} ${year}`;
            }

            return result;
        },
    },
    mounted() {
        this.$watch('selectedDate', () => {
            this.$emit('change', {
                date: this.selectedDate,
                month: this.selectedMonth,
                year: this.selectedYear,
            });
        });

        this.setSelectedDate(this.startMonth, this.startYear);
        this.groupMonthsByYear(this.selectedYear, this.selectedMonth);
    },
    methods: {
        groupMonthsByYear(startingYear, startingMonth) {
            let result = {};

            const d = new Date();
            d.setYear(startingYear);
            d.setMonth(startingMonth);

            let monthCount = 0;
            const monthsPerYear = 12;
            const numOfMonths = this.maxYears * monthsPerYear;
            let currYear = startingYear;
            let currYearMonths = [];
            for (let i = Number(startingMonth); monthCount <= numOfMonths; i++) {
                currYearMonths.push(i.toString());
                monthCount++;
                if (i > 0 && (i + 1) % monthsPerYear === 0 && monthCount < numOfMonths) {
                    result[currYear] = currYearMonths;
                    currYearMonths = [];
                    currYear = Number(currYear) + 1;
                    i = -1; //Incremented to 0 on next iteration
                }
            }
            result[currYear] = currYearMonths;

            this.monthsByYear = result;
        },
        setSelectedDate(month, year) {
            this.selectedMonth = month.toString();
            this.selectedYear = year.toString();
        },
    },
}; </script>

<style lang="scss">
@import 'lni-date-filter';
</style>