export default context => {
    const moduleId = context.getters.moduleId;
    const componentId = `${moduleId}_pager`;

    context.commit('setAttribute', {
        id: context.getters.moduleId,
        attribute: 'page',
        value: 1,
    }, {
        root: true,
    });

    setTimeout(() => {
        context.commit('setAttribute', {
            id: componentId,
            attribute: 'page',
            value: 1,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: componentId,
            attribute: 'totalItems',
            value: context.rootState[moduleId].filteredResults.length,
        }, {
            root: true,
        });

    });

};