<template>
    <div>
        <div v-show="showVideoDetailSection">
            <LniSafetyVideoDetail
                v-bind="{...detailAdditionalInfo}"
                @hide-detail="hideVideoDetail"
            />
            <div v-show="includeOnlineSection">
                <LniSafetyVideoOnlineDetail v-bind="{...detailAdditionalInfo}" />
            </div>
            <div v-show="includeDvdSection && includeOnlineSection">
                <hr>
            </div>
            <div v-show="includeDvdSection">
                <LniSafetyVideoDvdDetail
                    v-bind="{...detailAdditionalInfo}"
                    @show-detail="showVideoDetail"
                />
            </div>
        </div>
        <div v-show="showVideoDetailSection===false">
            <p>
                Borrowing videos from the safety library is for Washington state residents
                only. You must <a href="#">become a member</a> to reserve and borrow videos. Streaming
                videos may be viewed from anywhere.
            </p>
            <div>
                <lni-radios
                    :id="`${id}_searchType`"
                    label-text="I want to find:"
                    :is-column="true"
                    value="1"
                    :options="[
                        {text: 'Keyword or phrase', value: '1'},
                        {text: 'Subject or hazard', value: '2'},
                    ]"
                    :change-action="`${id}/toggleSearchType`"
                />
            </div>
            <div
                v-show="searchOption==='1'"
                class="lni-u-full-width lni-u-mt2"
            >
                <lni-input-search
                    :id="`${id}_input`"
                    ref="searchQueryInput"
                    name="query"
                    placeholder="Enter keyword(s), phrase, or Video ID"
                    :click-action="`${id}/searchForVideos`"
                    :search-action="`${id}/searchForVideos`"
                    :clear-action="`${id}/clearSearchResults`"
                    :empty-search-action="`${id}/searchForVideos`"
                />
            </div>
            <div
                v-show="searchOption==='2'"
                class="lni-u-full-width lni-u-mt2"
            >
                <lni-select
                    :id="`${id}_subjectOrHazards`"
                    :dense="true"
                    :inline="false"
                    label-text="Subject or Hazards"
                    placeholder="Any Subject or Hazard"
                    :options="subjectsOrHazardsOptions"
                    :change-action="`${id}/searchForVideos`"
                />
            </div>
            <div
                v-show="searchOption==='2'"
                class="lni-u-full-width lni-u-mt2"
            >
                <lni-select
                    :id="`${id}_subjectOrHazards`"
                    :dense="true"
                    :inline="false"
                    label-text="Subject or Hazards"
                    placeholder="Any Subject or Hazard"
                    :options="subjectsOrHazardsOptions"
                    :change-action="`${id}/searchForVideos`"
                />
            </div>
            <div class="lni-u-flex-wrap lni-u-flex lni-u-justify-between lni-u-mv1 lni-c-search-shrl__button-bar">
                <button
                    type="button"
                    class="lni-c-button --text lni-u-text--all-caps lni-u-type--bold lni-u-mt2"
                    :aria-expanded="`${advancedIsOpen}`"
                    :aria-controls="`${id}_collapse`"
                    @click="advancedIsOpen = !advancedIsOpen"
                >
                    Filter Results
                    <span
                        aria-hidden="true"
                        :class="`lnicon--caret--${advancedIsOpen? 'up':'down'}`"
                    />
                </button>
                <lni-button
                    :id="`${id}_button-clear`"
                    class="lni-u-mt2"
                    text="Clear Filters"
                    :click-action="[`${id}/clearFilters`]"
                    type="text"
                />
            </div>
        </div>
        <div v-show="showVideoDetailSection===false">
            <div
                v-show="advancedIsOpen"
                :id="`${id}_collapse`"
            >
                <div class="lni-u-flex-wrap lni-u-flex lni-u-full-width lni-u-justify-between">
                    <div class="lni-c-search-shrl__select">
                        <lni-select
                            :id="`${id}_videoFormats`"
                            :dense="true"
                            :inline="false"
                            label-text="Video Formats"
                            :options="videoFormatOptions"
                            :change-action="`${id}/filterByFormat`"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isSearchInvoked">
                <hr>
                <div class="lni-u-full-width lni-u-mt3 lni-u-bb">
                    <div v-if="loading">
                        Searching for {{ searchTermSpecified }}
                    </div>
                    <div v-else>
                        We found {{ filteredResults.length }}
                        <span
                            v-if="filteredResults.length === 1"
                        >
                            video
                        </span>
                        <span v-else>
                            videos
                        </span>
                        with the keyword(s) {{ searchTermSpecified }}.
                    </div>
                </div>
            </div>
            <div
                v-if="filteredResults?.length"
                class="lni-u-flex lni-u-flex-column lni-u-gap2"
            >
                <LniSafetyVideoSearchCard
                    v-for="(item) in filteredResults"
                    :key="item.titleId"
                    v-bind="{...item}"
                    @show-detail="showVideoDetail"
                />
            </div>
        </div>
        <lni-spinner
            v-show="loading"
            :id="`${id}_spinner`"
        />
        <lni-modal
            id="dialogException"
            title="Alert"
            :allow-close="true"
        >
            <template #content>
                <div class="lni-u-mb2">
                    An error occurred while trying to access information on the Contractor Fraud Reporting page.
                    <div class="lni-u-mt2">
                        If the error persists, please contact us to report this issue.
                    </div>
                    <div class="lni-u-mt2">
                        We're sorry for the inconvenience!
                    </div>
                </div>
            </template>
            <template #actions>
                <div class="lni-u-flex lni-u-justify-end">
                    <lni-button
                        id="testDialogOkButton"
                        class="lni-c-button lni-u-mr2"
                        click-action="closeDialog"
                        text="OK"
                    >
                        Ok
                    </lni-button>
                </div>
            </template>
        </lni-modal>
        <span id="alertContainer">
            <lni-informational-messages id="alertMsg" />
        </span>
    </div>
</template>

<script>
import LniSafetyVideoSearchCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-search-card.vue';
import LniSafetyVideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-detail.vue';
import LniSafetyVideoOnlineDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-online-detail.vue';
import LniSafetyVideoDvdDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-dvd-detail.vue';

export default {
    name: 'LniSafetyVideoSearch',
    components: {
        LniSafetyVideoSearchCard,
        LniSafetyVideoDetail,
        LniSafetyVideoOnlineDetail,
        LniSafetyVideoDvdDetail,
    },
    data() {
        return {
            advancedIsOpen: true,
            pageSize: 10,
            showVideoDetailSection: false,
        };
    },
    computed: {
        includeOnlineSection() {
            let formats = this.detailAdditionalInfo.formats.toLowerCase();
            if (formats.includes('online')) {
                return true;
            }
            return false;
        },
        includeDvdSection() {
            let formats = this.detailAdditionalInfo.formats.toLowerCase();
            if (formats.includes('dvd') || formats.includes('vhs') || formats.includes('CD')) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.$store.dispatch(`${this.id}/loadLookupData`, {
            oneLni: this.$oneLni,
        });
    },
    methods: {
        showVideoDetail(titleId) {
            this.$store.dispatch(`${this.id}/loadVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.$store.dispatch(`${this.id}/loadAdditionalVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.showVideoDetailSection = true;
            this.showOnlineVideoSection = true;
        },
        hideVideoDetail() {
            this.showVideoDetailSection = false;
        },
    },
}; </script>