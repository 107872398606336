
export default context => {
    const moduleId = context.getters.moduleId;
    const positionOfferValue = JSON.parse(context.rootState[`${moduleId}_position-offer`].value);
    const healthBenefitsValue = parseInt(context.rootState[`${moduleId}_health-benefits`].value);

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'displayMessage',
        value: !positionOfferValue && healthBenefitsValue === -1,
    }, {
        root: true,
    });
};