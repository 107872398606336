import  {
    loadStateFromSession,
    syncStore,
    syncUi,
    persist,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/sync';
import { initialize, getUiContent } from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/initialize.js';
import { submit, validateContactInfo } from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/submit.js';
import handleError from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/handleError.js';
import handleSuccess from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/handleSuccess.js';
import { getContentByIds } from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/api/getContentByIds';
import { onRecaptchaSuccess, triggerRecaptcha } from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/recaptcha.js';
import handleModal from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/actions/handleModal';
import {
    text,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/data/text';
import counties from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/data/counties.js';
import {
    fields,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/data/formModel.js';
const executeAfterMinimumDelay = (callback, delay, start) => {
    const elapsed =  new Date() - start;
    const timeLeft = delay - elapsed;
    setTimeout(callback, timeLeft > 0 ? timeLeft : 0);
};
export default () => ({
    state: {
        text,
        counties,
        formId: '',
        token: '',
        confirmationAppendedContentId: '',
        confirmationFromEmail: '',
        confirmationFromName: '',
        confirmationPrependedContentId: '',
        confirmationSubject: '',
        confirmationToEmail: '',
        submissionAppendedContentId: '',
        submissionFromEmail: '',
        submissionFromName: '',
        submissionPrependedContentId: '',
        submissionSubject: '',
        submissionToEmail: '',
        submissionToName: '',
        uiSuccessMessageId: '',
        uiLegalInformationId: '',
        success: false,
        status: {
            initialize: {
                pending: true,
                error: null,
            },
            submit: {
                pending: false,
                error: null,
            },
        },
        formData: fields(text, counties).map(({
            id,
            labelText,
            parentId,
            hide,
        }) => ({
            id,
            label: labelText,
            parentId,
            hide,
        })),
    },
    getters: {
        isPending(state) {
            return state.status.initialize.pending || state.status.submit.pending;
        },
        currentState(state) {
            return Object.entries(state.status).find(subState => subState[1].error);
        },
        errorMessage(state, getters) {
            if (!getters?.currentState) {
                return null;
            }
            return getters.currentState?.[1].error;
        },
        generalErrorMessage(state, getters) {
            if (!getters?.currentState) {
                return null;
            }
            const currentState = getters.currentState[0];
            return state.text[`generalError.${currentState}`];
        },
        uiContentMap(state) {
            return [
                {
                    contentIdentifier: state.uiSuccessMessageId,
                    contentField: 'body',
                    textKey: 'successMessage',
                },
                {
                    contentIdentifier: state.uiLegalInformationId,
                    contentField: 'body',
                    textKey: 'legalInformation',
                },
            ];
        },
        submissionEmailData(state) {
            const date = new Date().toLocaleString();
            return {
                title: `Workplace Safety & Health Retaliation Report ${date}`,
                formData: JSON.stringify(state.formData),
                subject: state.submissionSubject,
                toEmail: state.submissionToEmail,
                toName: state.submissionToName,
                fromName: state.submissionFromName,
                fromEmail: state.submissionFromEmail,
                emailType: 'submission',
                formId: state.formId,
            };
        },
        contactName(state) {
            return state.formData.find(({
                id, value,
            }) => id === 'contactName' && value).value;
        },
        emailContact(state) {
            return state.formData.find(({
                id, value,
            }) => id === 'contactEmail_input-text' && value).value;
        },
        confirmationEmailData(state, getters) {
            const date = new Date().toLocaleString();
            return {
                title: `Workplace Safety & Health Retaliation Form Submission ${date}`,
                formData: JSON.stringify(state.formData),
                subject: state.confirmationSubject,
                toEmail: getters.emailContact,
                toName: getters.contactName,
                fromName: state.confirmationFromName,
                fromEmail: state.confirmationFromEmail,
                prepend: state.confirmationPrependedContentId,
                emailType: 'confirmation',
                formId: state.formId,
            };
        },
    },
    actions: {
        initialize,
        submit,
        syncUi,
        syncStore,
        persist,
        validateContactInfo,
        loadStateFromSession,
        handleError,
        handleSuccess,
        getUiContent,
        getContentByIds,
        onRecaptchaSuccess,
        triggerRecaptcha,
        handleModal,
    },
    mutations: {
        SET_STATUS(state, { type, pending }) {
            if (pending) {
                state.pendingStart = new Date();
                state.status[type].pending = pending;
            } else {
                const minimumDelay = 200;
                executeAfterMinimumDelay(() => {
                    state.status[type].pending = pending;
                }, minimumDelay, state.pendingStart);
            }
        },
        SET_ERROR(state, { type, error }) {
            state.status[type].error = error;
        },
        UPDATE_FORM_ITEM(state, payload) {
            const itemIndex = state.formData.findIndex(item => item.id === payload.id);
            if (itemIndex === -1) {
                return;
            }
            const newItem = {
                ...state.formData[itemIndex],
                ...payload,
            };
            state.formData.splice(itemIndex, 1, newItem);
        },
        UPDATE_TEXT_VALUE(state, { key, value }) {
            state.text = {
                ...state.text,
                [key]: value,
            };
        },
    },
});