import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-late-payment/actions/setAttribute.js';
import resetOutputs from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-late-payment/actions/resetOutputs.js';

const usd = val => {
    const floatDig = 2;

    return '$' + parseFloat(val).toFixed(floatDig)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
};

export default context => {
    const moduleId = context.getters.moduleId;
    if (!context.rootGetters[`${moduleId}_error-container/isValid`]) {
        resetOutputs(context);
        return;
    }

    const penaltyFactor1 = 0.05;
    const interestRate = 0.01;
    const double = 2;
    const penaltyMin = 10;
    const penaltyRate = 0.1;
    const secondMonth = 2;
    const thirdMonth = 3;

    let total = 0;
    let interest = 0;
    let premium = parseFloat(context.rootState[`${moduleId}_num-premiums`].value);
    let months = parseFloat(context.rootState[`${moduleId}_num-months`].value);

    let firstMonthPenalty = penaltyFactor1 * premium;
    let penalty = firstMonthPenalty < penaltyMin ? penaltyMin : firstMonthPenalty;

    switch (months) {
        case 1:
            interest = interestRate * months * premium;
            break;
        case secondMonth:
            penalty = penaltyRate * premium;
            interest = interestRate * months * premium;
            break;
        case thirdMonth:
            penalty = penaltyRate * double * premium;
            interest = interestRate * months * premium;
            break;
        default:
            penalty = penaltyRate * double * premium;
            interest = interestRate * double * months * premium;
    }

    total =  parseFloat(penalty) + parseFloat(interest) + parseFloat(premium);

    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result`,
    }, usd(penalty));
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result1`,
    }, usd(interest));
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result2`,
    }, usd(total));
};