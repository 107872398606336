<template>
    <div class="lni-c-rtw-letter lni-c-full-width">
        {{ todaysDate }}
        <p class="lni-u-mt3">
            {{ firstName }} {{ lastName }}<br>
            {{ address.address1 }}<br>
            <span v-if="address.address2.length > 0">
                {{ address.address2 }}<br>
            </span>
            {{ address.city }}, {{ address.state }} {{ address.zip }}
        </p>
        <div class="lni-u-mt2 --row">
            <p>RE:</p>
            <p class="lni-u-pl2">
                Employer: {{ companyName }}<br>
                Claim no.: {{ claimNumber }}
            </p>
        </div>
        <p class="lni-u-mt3">
            Dear {{ firstName }},
        </p>
        <p class="lni-u-mt2">
            {{ positionOfferText.opening }} Your provider released you to perform the work activities outlined in the attached job analysis/description.
        </p>
        <ul class="lni-u-mt2 lni-u-ml4">
            <li>Please report for work to {{ supervisorName }} on {{ reportDate }}, {{ reportTime }}{{ reportTimeType }}, at {{ jobAddress }}.</li>
            <li>Your reporting supervisor can be contacted at {{ supervisorPhone }}.</li>
            <li>Your work schedule will be: {{ scheduledDays }}/{{ scheduledHours }}.</li>
            <li>You will be paid ${{ pay.rate }} {{ pay.type }}.</li>
            <li>{{ healthBenefitsText }}</li>
        </ul>
        <p class="lni-u-mt2">
            If your job involves fewer hours or reduced wages compared to the time of injury, you may be eligible for Loss of Earning Power (LEP) benefits. Contact your claim manager for more information.
        </p>
        <p class="lni-u-mt2">
            If you want to return to work earlier or need more time, please call to discuss. We are happy to work with you.
        </p>
        <p class="lni-u-mt2">
            {{ positionOfferText.closing }} It is our hope this job will support you as a valued employee. If you have difficulty performing the tasks you are assigned, you must notify your supervisor immediately.
        </p>
        <p class="lni-u-mt2">
            Your signature below acknowledges that you have reviewed this job offer. Declining this job may affect your eligibility for compensation benefits.
        </p>
        <div class="lni-c-rtw-letter__signature">
            <div class="--signature-line"></div>
            <div class="lni-u-full-width --row">
                <span>(Worker's Signature)</span>
                <span>(Date)</span>
            </div>
        </div>
        <div class="lni-u-mt2">
            <p>____ Yes, I accept this offer</p>
            <p>____ No, I do not accept this offer (please comment below)</p>
        </div>
        <div class="lni-u-mt2 lni-u-mb4">
            Comments:
        </div>
        <p>If you have any questions concerning this matter, please contact me at {{ contactPhone }}.</p>
        <div class="lni-u-mt2">
            Sincerely,
            <div class="lni-c-rtw-letter__signature">
                <div class="--signature-line"></div>
                <div class="lni-u-full-width --row">
                    <span>(Reporting Supervisor's Signature)</span>
                    <span>(Date)</span>
                </div>
            </div>
        </div>
        <div class="lni-c-rtw-letter__contact">
            <div class="--row">
                <p class="lni-u-mt2">
                    CC:
                </p>
                <p class="lni-u-mt2 lni-u-pl2">
                    Claims Manager<br>
                    Attending Provider
                </p>
                <p class="lni-u-mt2">
                    ENC:
                </p>
                <p class="lni-u-mt2 lni-u-pl2">
                    Approved Job Analysis/Description<br>
                    Self-addressed Stamped Envelope
                </p>
            </div>
        </div>
        <lni-button
            id="rtwLetterPrintBtn"
            class="lni-c-rtw-letter__print-btn lni-u-mt3"
            text="Print Letter"
            type="submit"
            @click="printLetter()">
        </lni-button>
    </div>
</template>

<script>
export default {
    name: 'lni-rtw-printable-letter',
    data() {
        return {
            errorMessage: '',
        };
    },
    computed: {
        todaysDate() {
            const today = new Date();
            const month = today.toLocaleString('default', {
                month: 'long',
            });
            return `${month} ${today.getDate()}, ${today.getFullYear()}`;
        },
        positionOfferText() {
            return this.positionOffer
                ? {
                    opening: 'We are pleased to offer you work which is expected to continue into the foreseeable future.',
                    closing: '',
                }
                : {
                    opening: 'We are pleased to offer temporary work while you recover from your industrial injury/illness.',
                    closing: 'It is our goal that this temporary assignment will aid in your recovery while you transition back into full work activities.',
                };
        },
        healthBenefitsText() {
            let result = '';
            if (this.healthBenefits === -1) {
                result = 'We are unable to offer the same health care benefits due to a change in our benefit program.';
            } else if (this.healthBenefits === 0) {
                result = 'No health care benefits provided at the time of injury';
            } else if (this.healthBenefits === 1) {
                result = 'Your health care benefits will resume at the level provided at the time of injury.';
            }

            return result;
        },
    },
    created() {
        window.addEventListener('popstate', () => {
            window.location.reload(); //Back button
        });
    },
    mounted() {
        if (history.state) {
            this.$store.commit(`${this.id}/SET_VALUES`, history.state);
        } else {
            window.location.href = this.formEndpoint;
        }
    },
    methods: {
        printLetter() {
            window.print();
        },
    },
};
</script>