<template>
    <div class="lni-c-box--content lni-c-safety-topic-card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <div class="lni-u-flex lni-u-justify-between">
            <span
                class="lni-c-safety-topic-card__channel lni-u-text--all-caps lni-u-type--xxs lni-u-mb1">
                {{ card.channel.text }}
            </span>
            <span
                aria-hidden="true"
                :class="[`lnicon--${iconType}`, 'lni-u-type--xl']"
                :aria-label="iconType"></span>
        </div>
        <h2>
            <a
                :href="card.path"
                class="lni-u-align-top lni-u-mr4 lni-u-text--no-underline lni-u-line-height--tight lni-u-type--md lni-u-type--bold">
                {{ card.title }}
            </a>
        </h2>
        <div class="lni-u-mt1 lni-u-type--xxs lni-u-text--all-caps">
            Interests:&nbsp;
            <span
                v-for="(relevance, index) in card.relevance"
                :key="`${relevance}_${index}`">
                {{ relevance.text }}<span v-if="index < card.relevance.length - 1">;&nbsp;</span>
            </span>
        </div>
        <div class="lni-u-flex">
            <div class="lni-u-full-width lni-u-text--right lni-u-line-height--tight">
                <span
                    v-if="card.referenceId"
                    class="lni-u-text--all-caps lni-u-type--xxs lni-u-line-height--tight">
                    {{ card.referenceId }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'lni-safety-topic-card',
    props: {
        card: Object,
    },
    computed: {
        iconType() {
            const dictionary = {
                xls: 'excel',
                xlt: 'excel',
                xlm: 'excel',
                xlsx: 'excel',
                xltx: 'excel',
                xlsm: 'excel',
                dot: 'word',
                dotm: 'word',
                dotx: 'word',
                doc: 'word',
                docx: 'word',
                docm: 'word',
                pdf: 'pdf',
                ppt: 'ppt',
                pot: 'ppt',
                pptx: 'ppt',
                potx: 'ppt',
                ppsx: 'ppt',
            };
            const fileName = this.card.fileType;
            if (fileName) {
                const fileNameParts = fileName.split('.');
                const partsLength = 2;
                if (fileNameParts.length > partsLength) {
                    const extension = fileNameParts[1];
                    const extensionValue = dictionary[extension];
                    if (extensionValue) {
                        return extensionValue;
                    }
                }
            }
            return '';
        },
    },
}; </script>