import submit from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/submit.js';
import syncUi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/syncUi.js';
import syncStore from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/syncStore.js';
import persist from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/persist.js';
import setOutOfState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/setOutOfState.js';
import counties from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/counties.json';
import decodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/decodeDotcmsCookie.js';

import {
    formFields,
} from './formModel';

export default () => ({
    state: {
        counties,
        formData: formFields,
        emailsByRegion: {},
        sendEmailEndpoint: '',
        senderEmail: '',
        showModalSpinner: false,
    },
    getters: {
        region(state, getters, rootState) {
            const selectedCounty = rootState[`${getters.moduleId}_county`].value;
            if (selectedCounty) {
                return state.counties.find(item => item.value === selectedCounty).region;
            }
            return 0; //default
        },
        consultantEmail(state, getters) {
            return state.emailsByRegion[getters.region];
        },
    },
    actions: {
        submit,
        syncUi,
        syncStore,
        persist,
        setOutOfState,
    },
    mutations: {
        INITIALIZE(state, payload) {
            const cookie = payload.oneLni.storage.cookie.get('consultationRequest');
            if (cookie && typeof decodeDotcmsCookie(cookie) === 'object'  ) {
                const data = decodeDotcmsCookie(cookie);
                state.formData = data.formData;
            }
        },
        UPDATE_FORM_ITEM(state, payload) {
            const itemIndex = state.formData.findIndex(item => item.id === payload.id);
            const newItem = {
                ...state.formData[itemIndex],
                ...payload,
            };
            state.formData.splice(itemIndex, 1, newItem);
        },

    },
});