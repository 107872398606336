import Slide from '@gov.wa.lni/framework.one-lni.directives/source/slideUtils.js';

// Slide accepts a value of 'isOpen' and a custom class to override inline styles
export default {
    inserted(el, binding, vnode) {
        el.slide = new Slide(el);

        // eslint-disable-next-line no-unused-expressions
        binding.value ? el.slide.initializeExpand(vnode) : el.slide.initializeCollapse();
    },
    bind(el, binding, vnode) {
        if (binding.onOpenStart) {
            vnode.context.$on('slide:open-start', binding.onOpenStart);
        }

        if (binding.onOpenEnd) {
            vnode.context.$on('slide:open-end', binding.onOpenEnd);
        }

        if (binding.onCloseStart) {
            vnode.context.$on('slide:close-start', binding.onCloseStart);
        }

        if (binding.onCloseEnd) {
            vnode.context.$on('slide:close-end', binding.onCloseEnd);
        }
    },
    unbind(el, binding, vnode) {
        vnode.context.$off('slide:open-start');
        vnode.context.$off('slide:open-end');
        vnode.context.$off('slide:close-start');
        vnode.context.$off('slide:close-end');
    },
    componentUpdated(el, binding, vnode) {
        if (binding.value !== binding.oldValue) {
            if (binding.class) {
                // eslint-disable-next-line no-unused-expressions
                binding.value ? el.slide.expandByClass(vnode) : el.slide.collapseByClass(vnode);
            } else {
                // eslint-disable-next-line no-unused-expressions
                binding.value ? el.slide.expand(vnode) : el.slide.collapse(vnode);
            }
        }
    },
};