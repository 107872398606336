<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <h3 class="lni-u-mb2">
            New Members
        </h3>
        <p>
            <b>
                If you've never borrowed videos before:
            </b>
            <a
                href="https://lni.wa.gov/forms-publications/F417-205-000.pdf"
                target="_blank"
            >
                Complete the Borrrower Agreement Form
            </a>
            (F417-205-000). Email the completed form to
            <a href="mailto:SHVideo@Lni.wa.gov">SHVideo@Lni.wa.gov</a>
        </p>
        <ul class="lni-u-ml3">
            <li>
                Read the
                <a
                    href="https://lni.wa.gov/safety-health/safety-training-materials/safety-videos/#video-library-policies"
                    target="_blank"
                >
                    Safety Video Library Policies
                </a>
            </li>
            <li>
                You may indicate your preferences for video format and language on the
                borrower agreement form.
            </li>
            <li>
                Videos are only loaned within Washington state
            </li>
        </ul>
        <div v-if="hasRelatedTitles">
            <h3 class="lni-u-mt2 lni-u-mb1">
                Related Videos
            </h3>
            <ul class="lni-u-ml3">
                <li
                    v-for="title in $props.relatedTitles"
                    :key="title.otherTitleId"
                    class="lni-u-mb1"
                >
                    <a
                        href
                        class="lni-u-type--medium lni-c-shrl-card__title lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
                        @click.prevent="$emit('show-detail', title.otherTitleId)"
                    >{{ title.titleName }}
                    </a>
                    <div>
                        Video ID: {{ title.otherTitleId }},
                        Language: {{ title.languages }},
                        Format {{ title.formats }}
                    </div>
                </li>
            </ul>
        </div>
        <h3 class="lni-u-mt2 lni-u-mb1">
            Video Production
        </h3>
        <div class="lni-u-mt2">
            <b>{{ $props.vendorName }}</b>
        </div>
        <span>Tel: {{ $props.vendorPhone }}</span>
        <span>Website:
            <a
                :href="$props.vendorWebsite"
                target="_blank"
            >{{ $props.vendorWebsite }}
            </a>
        </span>
    </div>
</template>

<script>
export default {
    name: 'LniSafetyVideoSearchDvd',
    /* eslint-disable */
    props: [
        'relatedTitles',
        'vendorName',
        'vendorPhone',
        'vendorEmail',
        'vendorWebsite',
    ],
    emits: ['show-detail'],
    computed: {
        hasRelatedTitles() {
            if(this.$props.relatedTitles.length>0) {
                return true;
            }
            return false;
        },
    },
    methods: {},
    /* eslint-enable */
};
</script>