import searchApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-search.api.js';
import lookupApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-lookups.api.js';

export default (context, payload) => {
    const utils = payload.oneLni.storeUtils;
    const id = payload.targetId;
    const query = payload.customData.camelCasedQuery;

    lookupApi(payload.oneLni).then(results => {
        utils.setAttribute(context, id, 'channelOptions', results[0]);
        utils.setAttribute(context, id, 'relevanceOptions', results[1]);
        utils.setAttribute(context, id, 'options', results[2]);
        utils.setAttribute(context, id, 'isInitialized', true);
    });

    searchApi(query, payload.oneLni).then(results => {
        utils.setAttribute(context, id, 'results', results);
        utils.setAttribute(context, id, 'filteredResults', results);
        utils.setAttribute(context, id, 'searchStatus', 'resolved');
    });
};