export default () => ({
    state: {
        minYears: 1,
        maxYears: 1,
        selectedMonth: '',
        selectedYear: '',
        startMonth: new Date().getMonth().toString(),
        startYear: new Date().getFullYear().toString(),
    },
    mutations: {
        SET_SELECTED_DATE(state, payload) {
            state.selectedMonth = payload.month;
            state.selectedYear = payload.year;
        },
    },
});