import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/initialize.js';
import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/search.js';
import clearFilters from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/clearFilters.js';
import updateResults from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updateResults.js';
import updatePage from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updatePage.js';
import updateMultiselectOptions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updateMultiselectOptions.js';

export default {
    initialize,
    search,
    clearFilters,
    updateResults,
    updatePage,
    updateMultiselectOptions,
};