<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <h3>
            Online Stream
        </h3>
        <span>Watch this video on YouTube</span>
        <legend class="lni-u-heading--3 lni-u-mt3 lni-u-mb1">
            Video Production
        </legend>
        <div
            v-for="media in $props.webInfoList"
            :key="media.onlineMediaId"
            class="lni-u-mb1"
        >
            <a
                :href="getVideoLink(media.youtubeId, media.onlineMediaUrl)"
                target="_blank"
            >{{ media.webLinkLabel }} {{ isLniVideo ? '' : '***' }}
            </a>
        </div>
        <div class="lni-u-mt2">
            <b>{{ $props.vendorName }}</b>
        </div>
        <span>Tel: {{ $props.vendorPhone }}</span>
        <span>Website:
            <a
                :href="$props.vendorWebsite"
                target="_blank"
            >{{ $props.vendorWebsite }}
            </a>
        </span>
        <p
            v-show="displayFirstFootnote"
            class="lni-u-mt2"
        >
            * Links to non-L&I websites are provided as a courtesy to customers when L&I considers
            external Web content to be useful and valuable to the public. However, we cannot vouch
            for, or take responsibility for, information contained on websites not administered by
            the Washington State Department of Labor &amp; Industries.
        </p>
        <p
            v-show="displaySecondFootnote"
            class="lni-u-mt2"
        >
            ** References in this website, or links from this website to any specific commercial
            products, processes, or services, or the user of any trade, firm, or corporation
            name is for the information and convenience of visitors to this site, and doesn't
            constitute endorsement, recommendation, or favoring by the Washington State Department
            of Labor & Industries, or its officers, employees or agents.
        </p>
    </div>
</template>

<script>
import isNullOrEmptyString from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/isNullOrEmptyString';
export default {
    name: 'LniSafetyVideoSearchOnline',
    data() {
        return {
            displayFirstFootnote: true,
            displaySecondFootnote: true,
            isLniVideo: false,
        };
    },
    methods: {
        getVideoLink(youtubeId, mediaUrl) {
            let url = '';
            const lniDomain = 'lni.wa.gov';
            if (!isNullOrEmptyString(mediaUrl)) {
                url = mediaUrl;
            }

            this.isLniVideo = url.indexOf(lniDomain) !== -1;

            if (isNullOrEmptyString(youtubeId)) {
                return mediaUrl;
            }

            return `https://www.youtube.com/watch?v=${youtubeId}`;
        },
    },
    /* eslint-disable */
    props:[
        'vendorName',
        'vendorPhone',
        'vendorEmail',
        'vendorWebsite',
        'webInfoList',
    ],
    /* eslint-enable */
};
</script>