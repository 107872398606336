<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <a
            href
            class="lni-u-type--medium lni-c-shrl-card__title lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
            @click.prevent="$emit('show-detail', titleId)"
        > {{ titleName }}
        </a>
        <p class="lni-u-line-height--tight lni-u-type-xs lni-c-shrl-card__column1 lni-u-mt3">
            {{ description }}
        </p>
        <div class="lni-u-flex lni-u-gap3 lni-u-mt3">
            <div>
                <div>Languages: {{ languages }}</div>
                <div>Release Date: {{ productionDate }} </div>
                <div>Length: {{ videoLength }} </div>
            </div>

            <div class="lni-u-mr0 lni-u-mlauto">
                <div>Title ID: {{ titleId }}</div>
                Format(s):
                <div
                    v-for="formatType in titleFormats"
                    :key="formatType"
                    class="shrl-c-search-result__format lni-u-inline-block"
                >
                    <span v-if="formatType.toUpperCase() ==='DVD'">
                        <!-- Add back in when CC SVG file is available.
                            <span class="lnicon--dvd lni-u-type--s lni-u-mr1"></span>
                        -->
                        <img
                            src="../lni-safety-video-search/images/dvd.gif"
                            alt="DVD"
                            class="lni-u-mr1"
                        >
                    </span>
                    <span v-if="formatType.toUpperCase()==='CC'">
                        <img
                            src="../lni-safety-video-search/images/ccap.png"
                            alt="Closed Captioning"
                            class="lni-u-mr1"
                        >
                    </span>
                    <span v-if="formatType.toUpperCase()==='ONLINE'">
                        <!-- Add back in when CC SVG file is available.
                            <span class="lnicon--youtube lni-u-type--s lni-u-mr1"></span>
                        -->
                        <img
                            src="../lni-safety-video-search/images/online.png"
                            alt="Online"
                            class="lni-u-mr1"
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LniSafetyVideoSearchCard',
    emits: ['show-detail'],
    methods: {},
    /* eslint-disable */
    props:[
        'titleName',
        'titleId',
        'videoLength',
        'productionDate',
        'languages',
        'description',
        'titleFormats',
    ],
    /* eslint-enable */
};
</script>