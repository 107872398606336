<template>
    <div>
        <form
            v-if="isInitialized"
            :id="`${id}_form`"
            action="/safety-health/safety-topics/search-by-topic"
            method="GET"
        >
            <p class="lni-u-ml2 lni-u-type--xs">
                Enter a keyword and choose from the list of available topics.
            </p>

            <div
                class="lni-c-condition-treatment__form-wrapper"
            >
                <lni-auto-suggestion
                    :id="`${id}_auto-suggest`"
                    ref="auto-suggest"
                    :fullWidth="true"
                    :options="options"
                    :value="query"
                    :enterAction="`${id}/search`"
                    :clearAction="`${id}/search`"
                    @mounted="setInitialAutoSuggestState"
                />
            </div>

            <p class="lni-u-ml2 lni-u-mv1 lni-u-type--xs">
                Available topics are organized under the <span class="lni-c-safety-topic__left-nav-string">left</span> navigation categories.
            </p>

            <div class="lni-u-mv2 lni-c-search-sharp__button-bar">
                <button
                    type="button"
                    class="lni-c-button --text lni-u-text--all-caps lni-u-type--bold lni-u-mt2"
                    @click="advancedIsOpen = !advancedIsOpen"
                >
                    Filter Results
                    <span
                        aria-hidden="true"
                        :class="`lnicon--caret--${advancedIsOpen? 'up':'down'}`"
                    />
                </button>
            </div>
            <div
                :id="`${id}_collapse`"
                v-slide="showFilters"
            >
                <div class="lni-c-search-sharp__filters">
                    <div class="lni-u-full-width lni-u-flex lni-u-flex-wrap lni-u-items-end">
                        <lni-select
                            id="relevance_select"
                            class="lni-u-mr2"
                            :dense="false"
                            :inline="false"
                            labelText="What is your interest in this topic?"
                            value=""
                            placeholder="ALL"
                            :options="relevanceOptions"
                            :changeAction="`${id}/updateResults`"
                        />
                        <lni-multiselect
                            id="channelOptions_multi-select"
                            class="lni-u-mr2"
                            labelText="Type of Content"
                            :options="$store.getters[`${id}/channelOptionsWithCounts`]"
                            :filled="true"
                            :hasInlineMessages="false"
                            :displayCount="true"
                            :changeAction="`${id}/updateResults`"
                        />
                    </div>
                    <lni-button
                        :id="`${id}_button-clear`"
                        class="lni-u-mt2"
                        text="Clear Filters"
                        :clickAction="`${id}/clearFilters`"
                        type="text"
                    />
                </div>
            </div>
        </form>
        <!-- Results -->
        <div
            v-if="searchResolved"
        >
            <div
                v-if="filteredResults.length || showNoRecordsMessage"
                class="lni-u-full-width lni-u-mt3 lni-u-bb"
            >
                {{ filteredResults.length }} results
            </div>
            <div v-if="pageOfFilteredItems.length">
                <lni-safety-topic-card
                    v-for="(card, index) in pageOfFilteredItems"
                    :id="`safety-topic_${index}`"
                    :key="`safety-topic_${index}`"
                    :card="card"
                    class="lni-u-mt3"
                />
            </div>
            <div aria-live="polite">
                <div v-if="showNoRecordsMessage">
                    <p class="lni-u-text--center lni-u-mv4 lni-u-mhauto">
                        We couldn't find any publications with this criteria. Adjust the search criteria and try again.
                    </p>
                </div>
            </div>

            <lni-pager
                v-show="filteredResults.length"
                :id="`${id}_pager`"
                :page="page"
                :pageSize="10"
                :showPager="false"
                :totalItems="filteredResults.length"
                @change-page="onChangePage"
            />
        </div>
        <div v-else>
            <lni-spinner
                :id="`${id}_spinner`"
                :inline="false"
            />
        </div>
    </div>
</template>
<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';
import safetyCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/lni-safety-topic-card.vue';

export default {
    name: 'LniSafetyTopicSearch',
    components: {
        'lni-safety-topic-card': safetyCard,
    },
    directives: {
        slide,
    },
    data() {
        return {
            advancedIsOpen: true,
            windowWidth: window.innerWidth,
            camelCasedQuery: '',
        };
    },
    computed: {
        searchResolved() {
            return this.searchStatus === 'resolved';
        },
        isMobile() {
            const mobileBreakPoint = 430;
            return this.windowWidth < mobileBreakPoint;
        },
        showFilters() {
            return this.isMobile ? this.advancedIsOpen : true;
        },
        pageOfFilteredItems() {
            const itemsPerPage = 10;
            let singlePageResults = this.filteredResults;

            let startItem = (this.page - 1) * itemsPerPage;
            return singlePageResults.slice(startItem, startItem + itemsPerPage);
        },
        showNoRecordsMessage() {
            return this.query && this.pageOfFilteredItems.length === 0;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);

        const params = this.getUrlParams();
        if (params.query && params.query.length > 0) {
            this.camelCasedQuery = params.query;
        }

        this.dispatchEvent(`${this.id}/initialize`, {
            camelCasedQuery: this.camelCasedQuery,
        });

    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        setInitialAutoSuggestState() {
            //due to auto-suggestion issues, we are setting manually
            if (this.camelCasedQuery) {
                const queryOption = this.options.find(option =>
                    option.value === this.camelCasedQuery);

                this.query = queryOption ? queryOption.text : this.camelCasedQuery;

                this.$refs['auto-suggest'].value = this.query;

                this.$refs['auto-suggest'].$refs[`${this.id}_auto-suggest_text`].query = this.query;
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        onChangePage(data) {
            this.$scrollTo(`#${this.id}_auto-suggest_text_search-text`);
            this.page = data.page;
        },
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length) {
                return {};
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
    },
}; </script>

<style lang="scss">
@import 'lni-safety-topic';
</style>