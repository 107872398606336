export default (context, payload) => {

    // TODO: Multi-select chosen change relevance that doesn't have multi-select option
    const relevance = context.rootState.relevance_select.value;
    const channels = context.rootState['channelOptions_multi-select'].value;

    let filteredResults = context.state.results;
    payload.sourceId = payload.sourceId === 'channelOptions_multi-select'
        ? payload.sourceId = context.getters.moduleId
        : payload.sourceId;

    if (relevance) {
        filteredResults = context.state.results.filter( obj =>
            obj.relevance.some(e => e.value === relevance));
    }

    if (channels && channels.length) {
        filteredResults = filteredResults.filter( obj =>
            channels.includes(obj.channel.value));
    }

    context.commit('setAttribute', {
        id: payload.sourceId,
        attribute: 'filteredResults',
        value: filteredResults,
    }, {
        root: true,
    });

    context.dispatch('updateMultiselectOptions');

    context.dispatch('updatePage', {
        sourceId: context.getters.moduleId,
    });
};