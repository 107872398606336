import ApiOptionsInterface from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/interfaces/apiOptionsInterface';
import VideoSearchOptionDataInterface from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/interfaces/videoSearchOptionDataInterface';

export default class VideoSearch {

    public jsonRequestData = {};

    constructor()   {
        this.jsonRequestData = this.getRequestData();
    }

    public optionData: VideoSearchOptionDataInterface = {
        keywordTerm: '',
        filterBy: 0,
        sortBy: 0,
        formatFilter: 2,
    };

    public getLibraryCatalogOptions: ApiOptionsInterface = {
        operation: '/SafetyVideos/Search',
        httpMethod: 'POST',
        body: this.jsonRequestData,
    };

    getRequestData(): any {
        return {
            searchText: this.optionData.keywordTerm,
            searchFilter: this.optionData.filterBy,
            sortField: this.optionData.sortBy || 0,
            formatFilter: this.optionData.formatFilter,
        };
    }
}