import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import AdditionalVideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/classes/additionalVideoDetail';
//import helper from './helpers';

export default function loadAdditionalVideoDetail(context:any, payload:any) {

    const oneLni = payload.oneLni;
    context.commit(sharedConstants.MUTATIONS.updateLoadingState, true);

    let videoDetail = new AdditionalVideoDetail(payload.titleId);

    postToService(videoDetail.getAdditionalVideoDetail,
        oneLni).then((response:
            {
                ok: any;
                data: any;
            }) => {

        if (!response.ok) {
            context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
            //helper.showDialog(context, 'dialogException', true, true);
            return;
        }
        context.commit(sharedConstants.MUTATIONS.setAdditionalVideoDetail, response.data);

        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
    });


}